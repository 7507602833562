/** @jsxImportSource @emotion/react */
import type {
    GetStaticPropsContext,
    InferGetStaticPropsType,
    NextPage,
    PreviewData,
} from "next";
import { ParsedUrlQuery } from "querystring";

import { HomePageTemplate } from "@/ui/templates/home_page";

import { generatePageLevelProps } from "@/util/data_util";
import { generatePreFooterData } from "@/util/strapi_data_util";
import { getGlobals, getHomePage } from "@/util/strapi_util";

export const getStaticProps = async ({
    preview: previewMode,
}: GetStaticPropsContext<ParsedUrlQuery, PreviewData>) => {
    const slug = "home";

    const globalsData = await getGlobals(previewMode);
    const pageData = await getHomePage(previewMode);

    return {
        props: {
            ...generatePageLevelProps({ previewMode, slug }),
            ...generatePreFooterData(
                globalsData,
                globalsData.globals.PreFooter,
                true,
                pageData.Theme.Footer_Media,
            ),
            globalsData,
            legacyGrid: false,
            pageData,
            renderDemo: true,
            renderNewNavigationBar: true,
            renderNotifications: true,
            renderPostFooter: true,
        },
    };
};

type PageProps = InferGetStaticPropsType<typeof getStaticProps>;

const Page: NextPage<PageProps> = (props) => {
    return <HomePageTemplate {...props.pageData} />;
};

export default Page;
